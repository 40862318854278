
import React, { useState, useEffect }  from "react";
import Page from "./Page";

import Lightbox from "yet-another-react-lightbox";
import {Zoom} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";

const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];

async function timeout(delay) {
      return new Promise( res => setTimeout(res, delay) );
}

function Gallery({url}) {
 
  const [open, setOpen] = useState(false);
  const [slideList, setSlides] = useState();
  const [imgIndex, setIndex] = React.useState(-1);



  useEffect(() => {
      //timeout because images are lazy loaded
      const timeoutId = setTimeout(() => {
        const figures = document.querySelectorAll('figure img');
        figures.forEach((img,index) => 

          img.addEventListener("click", (event) => {
            setIndex(index);          
          }),

        );

        var slides = [];
        slides = [...figures].map((img) => ({ asset: img.src, width: img.width, height: img.height}))

        setSlides(slides);
      }, 1000);
     
      return () => clearTimeout(timeoutId);
  }, []);

    const slide = slideList && slideList.map(({asset, width, height }) => ({
      src: asset,
      width,
      height,
     // srcSet: breakpoints.map((breakpoint) => ({
     //   src: asset,
     //   width: breakpoint,
     //   height: Math.round((height / width) * breakpoint),
    //  }))
    }));

  const zoomRef = React.useRef(null);
    
    return (     
      
        <>       
          <Page url={url}/>
          <Lightbox plugins={[Zoom]} zoom={{ ref: zoomRef }} index={imgIndex} open={imgIndex >= 0} close={() => (setOpen(false),setIndex(-1))} slides= {slide} />
        </>     
    );
  
}

export default Gallery;