import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Navigate, useLocation} from 'react-router-dom';

export const PrivateRoute = ({ children, roles, ...rest }) => {
    const location = useLocation();
    const {keycloak} = useKeycloak();
    const isAutherized = (roles) => {
        
        if (keycloak && roles && keycloak.authenticated) {
            return roles.some(r => {
                const realm =  keycloak.hasRealmRole(r);                
                return realm;
            });       
        }
        return false;
    }
   
    if(!isAutherized(roles) ) {
      
        console.log(location.pathname);
        keycloak.login();
        return false;
    }                     
                    
    return children;
}