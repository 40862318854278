
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';

import NavbarLeden from './/NavbarLeden';
import UserInfo from './/UserInfo';


export default function App() {
  const [showBasic, setShowBasic] = useState(false);


  return (
    <MDBNavbar expand='xl' light bgColor='light'>
      <MDBContainer fluid>
      <Link to='/Home'>
        <MDBNavbarBrand href='#'><img src="/logo.png" height="100px" className="d-inline-block align-top" alt="" />Gereformeerde<br />Gemeente<br />Zoetermeer</MDBNavbarBrand>
</Link>
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>

            <MDBNavbarItem>
            
            <MDBDropdown>
              
              <MDBDropdownToggle tag='a' className='nav-link' role='button'>
              Over ons
                </MDBDropdownToggle>
                <MDBDropdownMenu>                
                  <Link to='/OverOns/WatGelovenWij'><MDBDropdownItem link>Wat geloven wij?</MDBDropdownItem></Link>
                  <Link to='/OverOns/Gemeente'> <MDBDropdownItem link>Gemeente</MDBDropdownItem></Link>
                  <Link to='/OverOns/VeiligeKerk'> <MDBDropdownItem link>Veilige kerk</MDBDropdownItem></Link>
                  <Link to='/OverOns/Kerkgebouw'><MDBDropdownItem link>Kerkgebouw</MDBDropdownItem></Link>
                </MDBDropdownMenu>
            </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to='/Diensten'>
                <MDBNavbarLink href='Diensten'>Diensten</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>

            <NavbarLeden />
            <MDBNavbarItem>
              <Link to='/Links'>
                <MDBNavbarLink aria-current='page'>
                  Links
                </MDBNavbarLink >
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
            <Link to='/Contact'>
                <MDBNavbarLink aria-current='page'>
                  Contact
                </MDBNavbarLink >
              </Link>
            </MDBNavbarItem>
          </MDBNavbarNav>
          
          <UserInfo />
          </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}