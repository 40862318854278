import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import './gallery.css';
import './wordpress-custom.css';
import './wordpress.css';
import './App.css';



import { ApolloProvider } from "@apollo/client";
import { useApollo } from "./ApolloClient/apollo";
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import KeyCloack from './services/KeyCloak'
import { AppRouter } from './AppRouter'; 

function App() {

  return ( 
    <ReactKeycloakProvider authClient={KeyCloack}  initOptions={{
        onLoad: "check-sso", checkLoginIframe: false
    }}>
     
        <div className="App">     
          <AppRouter />    
        </div>
    
    </ReactKeycloakProvider>     
  );
}

function IsUserAgentBot() {
  const agent = window.navigator.userAgent;

const botUserAgentsArray = [
            'googlebot',
            'bingbot',
            'linkedinbot',
            'mediapartners-google',
            'lighthouse',
            'insights',
];

var isBotUserAgent = 0;
for (var j = 0; j < botUserAgentsArray.length; j++){
    if (agent.toLowerCase().indexOf(botUserAgentsArray[j].toLowerCase()) !== -1){
        console.log("botUserAgent found: " + botUserAgentsArray[j].toLowerCase());
        isBotUserAgent = 1;
        break;
    }
}

return isBotUserAgent == 1;
}

export default App;
