import React from 'react';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import {
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';


export default function NavbarLeden() {
    const {keycloak} = useKeycloak();


    return (
       
              <>
                 {!!keycloak.authenticated && (
                  <MDBNavbarItem>
        <MDBDropdown>
          <MDBDropdownToggle tag='a' className='nav-link' role='button'>
            Verenigingen
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <Link to='/Verenigingen/OraEtLabora'><MDBDropdownItem link>-12 Ora et Labora</MDBDropdownItem></Link>
            <Link to='/Verenigingen/Min16'><MDBDropdownItem link>-16</MDBDropdownItem></Link>
            <Link to='/Verenigingen/Plus16'> <MDBDropdownItem link>+16</MDBDropdownItem></Link>
            <Link to='/Verenigingen/Slamat'><MDBDropdownItem link>Slamat</MDBDropdownItem></Link>
          </MDBDropdownMenu>
        </MDBDropdown>
        </MDBNavbarItem>
                 )}
     
          {!!keycloak.authenticated && (
      <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                Leden
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <Link to='/Leden/Agenda'><MDBDropdownItem link>Agenda</MDBDropdownItem></Link>
                <Link to='/Leden/Activiteiten'><MDBDropdownItem link>Activiteiten</MDBDropdownItem></Link>
                <Link to='/Leden/Petitie'><MDBDropdownItem link>Petitie</MDBDropdownItem></Link>
                <Link to='/Leden/Pelgrimskerk'><MDBDropdownItem link>Pelgrimskerk</MDBDropdownItem></Link>
                <Link to='/Leden/Adressen'><MDBDropdownItem link>Adresgids</MDBDropdownItem></Link>
                <Link to='/Leden/Beleid'><MDBDropdownItem link>Beleid</MDBDropdownItem></Link>
                <Link to='/Leden/Commissies'><MDBDropdownItem link>Commissies</MDBDropdownItem></Link>
                <Link to='/Leden/Thuisfrontcommissie'><MDBDropdownItem link>TFC</MDBDropdownItem></Link>
                <Link to='/Leden/Kaarten'><MDBDropdownItem link>Kaarten</MDBDropdownItem></Link>
                <Link to='/Leden/Boekentafel'><MDBDropdownItem link>Boekentafel</MDBDropdownItem></Link>
                <Link to='/Leden/kerknieuws'><MDBDropdownItem link>Kerknieuws</MDBDropdownItem></Link>
              </MDBDropdownMenu>
            </MDBDropdown>
            </MDBNavbarItem>
          )}
        </>
   

    );
}